export default interface User {
    id: number,
    role?: number,
    session_expires?: number,
    reg_expires?: number,
    reg_date: number,
    favorites?: Array<number>,
    cart?: Array<number>,
    uuid: string,
    name: string,
    surname?: string,
    email?: string,
    password?: string,
    google_id?: string,
    facebook_id?: string,
    session_token?: string,
    reg_token?: string,
    avatar?: string,
    persistentLink?: boolean
}

export function newUser(options?: Partial<User>): User {
    const defaults = {
        id: 0,
        reg_date: 0,
        uuid: '',
        name: ''
    }
    return {
        ...defaults,
        ...options
    }
}
