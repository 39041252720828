
import { defineComponent } from 'vue';
import LoginRegister from './LoginRegister.vue'

export default defineComponent({
  name: 'Header',
  components: {LoginRegister},
  data() {
    return {
      searchQuery: '',
      loginBtnClicked: false,
      showProfileOptions: false
    }
  },
  methods: {
    openMenu(e: Event) {
      if(e) {
        e.preventDefault()
        this.$emit('menu-on')
      }
    },
    goToProfile() {
      this.$router.push(`/profile/${this.$store.getters.user.id}`)
      // this.showProfileOptions = false
      const el = this.$refs['prof-btn'] as HTMLButtonElement
      if (el) el.blur()
    },
    logOut() {
      this.$store.dispatch('testLogin', null)
      this.showProfileOptions = false
    }
  }
});

