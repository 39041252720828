
export default interface PRODUCT {
    id: number,
    link: string,
    mainImage: string,
    otherImages: Array<string>,
    description: string,
    images?: string,
    image?: string,
    name: string,
    ratio?: string,
    discount?: number,
    discount_expires?: number,
    reviews_cnt?: number,
    reviewsNumber?: number,
    price?: number,
    freeShipping?: boolean,
    priceToString: (accountingDiscount?: boolean) => string
}

export function initProductCard(options?: Partial<PRODUCT>): PRODUCT {
    const defaults: PRODUCT = {
        id: -1,
        discount: 0,
        reviews_cnt: 0,
        link: '',
        mainImage: '',
        otherImages: [],
        images: '',
        description: '',
        name: '',

        price: Math.floor(Math.random() * 10000),
        priceToString: function (accountingDiscount?: boolean): string {
            if (this.price == null) return '----'
            if (this.price <= 0) return '0.00'
            else {
                if (this.price < 100) {
                    if (!accountingDiscount || !this.discount || (this.discount && this.discount <= 0)) return `0.${this.price}`
                    else {
                        const withDiscount = this.price / 100 * this.discount
                        console.log(withDiscount, this.price - withDiscount)
                        const priceString = (this.price - withDiscount).toString().split('')
                        priceString.splice(priceString.length - 2, 0, '.')
                        return priceString.join('')
                    }
                } else {
                    if (!accountingDiscount || !this.discount || (this.discount && this.discount <= 0)) {
                        const priceString = this.price.toString().split('')
                        priceString.splice(priceString.length - 2, 0, '.')
                        return priceString.join('')
                    } else {
                        const withDiscount = this.price / 100 * this.discount
                        console.log(withDiscount, this.price - withDiscount)
                        const priceString = (this.price - withDiscount).toString().split('')
                        priceString.splice(priceString.length - 2, 0, '.')
                        return priceString.join('')
                    }
                }
            }
        }
    }
    if (options?.images != null) {
        // console.log('PRODUCT HAS IMAGES')
        defaults.otherImages = options?.images.split(',')
        defaults.mainImage = defaults.otherImages[0]
    }
    if (options?.image != null) {
        defaults.mainImage = options?.image
    }
    return {
        ...defaults,
        ...options,
    }
}
