
import {defineComponent} from "vue"

export default defineComponent({
  name: "Notification",
  props: {
    type: {type: String, default: ''},
    text: {type: String, default: ''}
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    text: function () {
      if (this.text !== '') {
        // console.log('NOTIFICATION!!!!!!')
        this.showNotification()
      } else {
        this.show = false
      }
    }
  },
  methods: {
    showNotification() {
      let delay = 0
      switch (this.type) {
        case '':
        case 's':
          delay = 5000
          break
        case 'w':
          delay = 7000
          break
        case 'd':
          delay = 9000
      }
      this.show = true
      setTimeout(() => {
        this.$store.dispatch('notify', {type: '', text: ''})
      }, delay)
    }
  }
})
