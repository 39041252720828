
import {defineComponent} from "vue"
import {sha256} from 'js-sha256'
import apiService from '../api/api'
import {newUser} from "@/interfaces/user";

export default defineComponent({
  props: {
    show: Boolean
  },
  name: "LoginRegister",
  data() {
    return {
      loginOnly: true,
      action: 'login',
      user: {
        email: '',
        password: '',
        name: '',
        persistentLink: true
      },
      emailRegex: /\w+@\w+\.\w{2,}/,
      validatedPasswordValue: '',
      selectedText: '',
      pswdInputInFocus: false
    }
  },
  setup() {
    // console.log('')
  },
  computed: {
    allFieldsAreFilled() {
      // console.log('valid', this.emailValid())
      return !this.loginOnly ? this.emailValid() && (this.user.password !== '' && this.user.password === this.validatedPasswordValue) && this.user.name !== ''
          : this.emailValid() && this.user.password !== ''
    }
  },
  methods: {
    async logInWithFacebook() {
      // const headers = {'Access-Control-Allow-Origin': '*'}
      let newWindow = open('https://www.facebook.com/v17.0/dialog/oauth?client_id=966242223397117&redirect_uri={/}&state={{st=state123abc,ds=123456789}}', 'example', 'width=300,height=300')
      if (newWindow) {
        newWindow.focus();

        // alert(newWindow.location.href);
      }
      // axios.get(`http://localhost:55550/fb`).
      // then((resp: any) => {
      //   console.log('Auth resp: ', resp.data)
      //
      // }).
      //     catch((e: any) => console.error('auth error: ', e))
    },
    exit() {
      // this.loginOnly = true
      this.$emit('exit')
    },
    emailValid() {
      return this.emailRegex.test(String(this.user.email).toLowerCase())
    },
    authorization() {
      // console.log(JSON.stringify(this.user))
      const hash = sha256.create().update(this.user.password).hex()
      if (!this.loginOnly) {
        // this.$store.dispatch('testLogin', {id: 1, name: this.user.name, email: this.user.email, password: this.user.password, uuid: 'ertf-er45-hthd-5555'})
        apiService.register({email: this.user.email, name: this.user.name, password: hash}).then((r: any): any => {
          console.log(r)
          if (r.data.error) {
            console.error('ERROR: ', r.data.error)
            this.$store.dispatch('notify', {type: 'd', text: r.data.error})
            return
          }
          this.$store.dispatch('notify', {type: 's', text: `Регистрация прошла успешно<br/>Перейдите по ссылке, чтобы подтвердить почтовый адрес`})
        }).catch((e: any): any => {
          console.error(e)
        })
        this.closeOnAuth()
      } else {
        apiService.logIn({email: this.user.email, password: hash}).then((r: any): any => {
          // console.log(r)
          if (r.data.error) {
            console.error('ERROR: ', r.data.error)
            this.$store.dispatch('notify', {type: 'd', text: r.data.error})
            return
          }
          const user = newUser(r.data.user)
          // console.log('USER: ', user)
          if (user && user.id) {
            localStorage.jl_sid = user.session_token
            localStorage.jl_sid_exp = user.session_expires
            this.$store.dispatch('setUser', user)
            this.$store.dispatch('notify', {type: 's', text: `Авторизован! Добро пожаловать, ${user.name}`})
            this.closeOnAuth()
          }
        }).catch((e: any): any => {
          console.error(e)
        })
      }
    },
    closeOnAuth() {
      this.exit()
      this.$router.replace('/')
    },
    checkEmptyFields() {
      const func = function (input: HTMLInputElement, placeholder?: string, withValue?: boolean) {
        if (input) {
          input.classList.add('invalid')
          if (placeholder && placeholder !== '') input.placeholder = placeholder
          let prevVal = ''
          if (withValue) {
            prevVal = input.value
            input.value = ''
          }
          setTimeout(() => {
            input.placeholder = ''
            input.classList.remove('invalid')
            if (withValue) input.value = prevVal
          }, 3000)
        }
      }
      if (!this.emailValid()) func(this.$refs['_email'] as HTMLInputElement, this.user.email === '' ? 'Введите адрес почты' : undefined)
      if (this.user.password === '') func(this.$refs['_pass'] as HTMLInputElement, 'Введите пароль')
      if (!this.loginOnly && this.user.name === '') func(this.$refs['_name'] as HTMLInputElement, 'Введите имя')
      if (!this.loginOnly && this.validatedPasswordValue != this.user.password) func(this.$refs['_pass2'] as HTMLInputElement, this.validatedPasswordValue === '' ? 'Повторите пароль' : undefined)}
  },
})
