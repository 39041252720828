import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { onMenuOn: _ctx.openMenu }, null, 8, ["onMenuOn"]),
    _createVNode(_component_Menu, {
      items: _ctx.mainMenuItems,
      "is-main-menu": true
    }, null, 8, ["items"]),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer, { "fill-white": _ctx.footerFillWithWhite }, null, 8, ["fill-white"]),
    _createVNode(_component_Notification, {
      type: this.$store.getters.notification.type,
      text: this.$store.getters.notification.text
    }, null, 8, ["type", "text"])
  ], 64))
}