
import { defineComponent, ref } from 'vue'
import {mapActions} from "vuex"
import Card from '../components/Card.vue'
import PRODUCT, {initProductCard} from "../interfaces/product"
import apiService from "@/api/api"

export default defineComponent({
  name: 'Home',
  components: {
    Card,
  },
  // data() {
  //   // const categories: CARD[] = []
  //   return {
  //     categories: Array as PropType<CARD[]>
  //   }
  // },
  setup() {
    const popularCategoriesLinks = ref<PRODUCT[]>([])
    const recentlyViewedProducts = ref<PRODUCT[]>([])
    const popularProducts = ref<PRODUCT[]>([])
    const newProducts = ref<PRODUCT[]>([])
    return {
      categorieLinks: popularCategoriesLinks,
      recentlyViewedProducts,
      popularProducts,
      newProducts
    }
  },
  beforeMount() {
    // console.log(initCard())
    // console.log('RECENTLY ', this.recentlyViewedProducts)
    this.init().then(() => {
      this.categorieLinks = this.$store.getters.popularCategories
      // this.recentlyViewedProducts = this.$store.getters.recentlyViewed([12,34,68,96,84,22])
      apiService.get('rvp', this.$store.getters.recentlyViewed).then((r: any): any => {
        // console.log('rvp', r.data)
        if (!r.data.error && r.data.products) {
          const prods = r.data.products
          for (let i = 0; i < prods.length; i++) {
            this.recentlyViewedProducts.push(initProductCard(prods[i]))
          }
        }
      })
      apiService.get('pp').then((r: any): any => {
        // console.log('pp', r.data)
        if (!r.data.error && r.data.products) {
          const prods = r.data.products
          for (let i = 0; i < prods.length; i++) {
            this.popularProducts.push(initProductCard(prods[i]))
          }
          // console.log(JSON.stringify(this.popularProducts))
        }
      })
      apiService.get('np').then((r: any): any => {
        // console.log('np', r.data)
        if (!r.data.error && r.data.products) {
          const prods = r.data.products
          for (let i = 0; i < prods.length; i++) {
            this.newProducts.push(initProductCard(prods[i]))
          }
          // console.log(JSON.stringify(this.newProducts))
        }
      })
    }).catch(() => {
      console.error('no server response')
    })
  },
  methods: {
    ...mapActions([
       'init'
    ]),
    // addView(id: number) {
    //   apiService.addView('c', id)
    // }
  }
});
