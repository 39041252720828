
import { defineComponent, PropType } from 'vue';
import MENU_ITEM from "@/interfaces/menu_item";

export default defineComponent({
  name: 'Menu',
  props: {
    items: {type: Array as PropType<MENU_ITEM[]>, required: true},
    isMainMenu: {type: Boolean, default: false},
    activeKey: {type: String, default: ''}
  }
})
