
import { defineComponent } from 'vue';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";
import Notification from "@/components/Notification.vue";
import MENU_ITEM from "@/interfaces/menu_item";
import apiService from "@/api/api";
import {newUser} from "@/interfaces/user";
// import StarRating from '../node'

export default defineComponent({
  name: 'App',
  components: {Header,Footer,Menu,Notification},
  data() {
    const mainMenuItems: Array<MENU_ITEM> = []
    return {
      mainMenuItems
    }
  },
  async beforeMount() {
    // const sessionData = await this.$store.dispatch('init')
    // console.log('APP data inited: ', sessionData, this.$store.getters.popularCategories)
    this.mainMenuItems = [
      {title: 'Украшения и аксессуары'},{title: 'Одежда и обувь'},{title: 'Дом и стиль жизни'},{title: 'Свадьбы и вечеринки'},
      {title: 'Игрушки и развлечения'},{title: 'Искусство и коллекционирование'},{title: 'Материалы для творчества'},{title: 'Винтаж'}
    ]
  },
  mounted() {
    // setTimeout( () => {
    //   this.$store.dispatch('notify', {type: 's', text: 'Test@! sldkfjkfnsd lknejlskfsdkfjsdlkfsd sdlfjsldkfnsldfkgnskjg sdlkjfsldkf slkfsdlkf lkjgrejtwrgn sndflk sndfl djfkjdf wkfjdbsdkjfbk jjkfbskdjfb ksbdfkwbfwebf kjfb skfbwkebfewk fb kbksdbfksdbfksdbfksd bskdfbkwebfkw bfskdfbksd fbkwebf kwfebksdfbfb webf skdfbksd b'})
    // }, 2000)
    // this.$store.dispatch('init')
    if (localStorage.jl_sid && localStorage.jl_sid_exp) {
      // console.log('Has session token: ', localStorage.jl_sid)
      if (Math.round(Date.now() / 1000) >= +localStorage.jl_sid_exp) {
        localStorage.removeItem('jl_sid')
        localStorage.removeItem('jl_sid_exp')
        return
      }
      apiService.logIn().then((r: any): any => {
        // console.log(r)
        if (r.data.error) {
          // console.error('ERROR: ', r.data.error)
          if (r.data.error === 'ERR_NOTFOUND') {
            localStorage.removeItem('jl_sid')
            localStorage.removeItem('jl_sid_exp')
          }
          return
        }
        const user = newUser(r.data.user)
        // console.log('USER: ', user)
        if (user && user.id) {
          localStorage.jl_sid = user.session_token
          localStorage.jl_sid_exp = user.session_expires
          this.$store.dispatch('setUser', user)
        }
      }).catch((e: any): any => {
        console.error(e)
      })
    }
  },
  computed: {
    footerFillWithWhite() {
      return this.$route.path !== '/'
    }
  },
  methods: {
    openMenu() {
      // alert('menu')
    }
  }
});

