
import {defineComponent, PropType} from "vue";
import {mapGetters, mapActions} from "vuex";
import PRODUCT from "@/interfaces/product";
import apiService from "@/api/api";
import StarRating from './star_ratio/star-rating.vue'

export default defineComponent ({
  name: "Card",
  components: {StarRating},
  props: {
    card: {type: Object as PropType<PRODUCT>, required: true},
    cardType: {type: String as PropType<'anchor' | 'product' | 'product-small' | 'product-wide' | 'recommended'>, default: 'anchor'}
  },
  data() {
    return {
      cardWidth: 0,
      aspectRatio: 1,
      hover: false,
      testRatio: 2.67
    }
  },
  computed: {
    ...mapGetters([
        'isInFavorites',
        'isInCart'
    ])
  },
  beforeMount() {
    switch (this.cardType) {
      case 'anchor':
      case 'product':
        this.aspectRatio = 1.25
        break
      case 'product-small':
        this.aspectRatio = 1.5
        break
      case 'product-wide':
        break
      case 'recommended':
        break
      default:
    }
    // console.log('before mount: ', this.cardType)
  },
  mounted() {
    this.setImgHeight()
    window.addEventListener('resize', this.setImgHeight)
  },
  unmounted() {
    window.removeEventListener('resize', this.setImgHeight)
  },
  watch: {
    cardType: function (oldVal, newVal) {
      console.log('watch: ', oldVal, newVal)
    }
  },
  methods: {
    ...mapActions([
        'addToFavorites',
        'removeFromFavorites',
        'addToCart',
        'removeFromCart'
    ]),
    getSrc(image: string): string {
      return apiService.getSrc(image, this.isAnchorCard() ? 'c' : 'p')
    },
    isAnchorCard() { return this.cardType === 'anchor' },
    isProductCard() { return this.cardType === 'product' },
    isProductCardSmall() { return this.cardType === 'product-small' },
    isProductCardWide() { return this.cardType === 'product-wide' },
    isRecommendedCard() { return this.cardType === 'recommended' },
    favoritesClick() {
      console.log(this.isInFavorites(this.card.id))
      this.isInFavorites(this.card.id) === true ? this.removeFromFavorites(this.card.id) : this.addToFavorites(this.card.id)
    },
    setImgHeight() {
      const imgDiv: HTMLDivElement = this.$refs[`card-img-${this.card.id}`] as HTMLDivElement
      // console.log(img.clientWidth, this.aspectRatio)
      if (imgDiv != null) imgDiv.style.height = (imgDiv.clientWidth / this.aspectRatio) + 'px'
      // console.log('card mounted: ', this.$refs)
      this.$el.onmouseenter = () => { this.hover = true }
      this.$el.onmouseleave = () => { this.hover = false }
    },
    addView() {
      apiService.addView(this.isAnchorCard() ? 'c' : 'p', this.card.id)
    }
  }
})
