export default interface CATEGORY {
    id: number,
    name: string
    views: number
}

export function newCategorie(options?: Partial<CATEGORY>): CATEGORY {
    const defaults = {
        id: 0,
        name: '',
        views: 0
    }
    return {
        ...defaults,
        ...options,
    }
}