import axios, {AxiosResponse} from "axios"

const clientURL: any = {
    development: 'http://localhost:55550/',
    production: 'https://julia.yovogames.com/'
}

const apiURL: any = {
    development: /*'http://localhost:55550/'*/'https://api.julia.yovogames.com/',
    production: 'https://api.julia.yovogames.com/'
}

function newFormData(data?: any, image?: any) {
    const formData = new FormData()
    if (data != null) formData.append('Data', typeof data === 'string' ? data : JSON.stringify(data))
    if (image != null) formData.append('Image', image)
    return formData
}

function getSrc(image: string, dir: string) :string {
    console.log('getting src: ', image, dir);
    if (!image || !dir) return '';
    // console.log('getting src: ', image, dir);
    return apiURL[process.env.NODE_ENV] + dir + '/' + image;
}

function logIn(data: any): Promise<AxiosResponse<any>> {
    const formData = newFormData(data)
    return axios.post(`${clientURL[process.env.NODE_ENV]}login`, formData, {responseType: 'json' , headers: {'Authorization': localStorage.jl_sid || ''}});
}

function register(data: any): Promise<AxiosResponse<any>> {
    const formData = newFormData(data)
    return axios.post(`${clientURL[process.env.NODE_ENV]}register`, formData, {responseType: 'json'})
}

function getRecentlyViewed(): Promise<AxiosResponse<any>> {
    const formData = newFormData()
    return axios.post(`${apiURL[process.env.NODE_ENV]}get/rvp`, formData, {responseType: 'json' , headers: {'Authorization': localStorage.jl_sid || ''}})
}

function get(key: string, data?: any): Promise<AxiosResponse<any>> {
    // console.log('api get data', key, data)
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}get/${key}`, formData, {responseType: 'json' , headers: {'Authorization': localStorage.jl_sid || ''}})
}

function addView(key: string, id: number):  Promise<AxiosResponse<any>> {
    return axios.post(`${apiURL[process.env.NODE_ENV]}add-view/${key}/${id}`, {}, {responseType: 'json' , headers: {'Authorization': localStorage.jl_sid || ''}})
}


const apiService: any = {
    getSrc: getSrc,
    logIn: logIn,
    register: register,
    get: get,
    addView: addView
}

export default apiService
