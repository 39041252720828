import { createStore } from 'vuex'
import PRODUCT, {initProductCard} from "@/interfaces/product";
import CATEGORY, {newCategorie} from "@/interfaces/categorie";
import User from "@/interfaces/user";
import apiService from "@/api/api";

function getNewLink(apiLink?: boolean): Link {
  const link: Link = {} as Link
  link.development = `http://localhost:${apiLink != null ? '55551' : '8080'}/`
  link.production = `https://${apiLink != null ? 'api.' : ''}julia.yovogames.com/`
  link.getLink = () => {
    console.log('getlink: ', process.env.NODE_ENV, JSON.stringify(link))
    if (process.env.NODE_ENV === 'production') return link.production
    else return link.development
  }
  return link
}

// function getTestProducts(cnt: number, ids?: Array<number>): Array<PRODUCT> {
//   const templates = [
//     {id: 1, title: 'Подарки на годовщину', image: 'https://i.etsystatic.com/11486790/r/il/09528c/2809353368/il_340x270.2809353368_l0rq.jpg'},
//     {id: 2, title: 'Подарки для него', image: 'https://i.etsystatic.com/19483987/r/il/42cd8d/2512481731/il_340x270.2512481731_8gz7.jpg'},
//     {id: 3, title: 'Подарки для нее', image: 'https://i.etsystatic.com/15999839/r/il/57349a/2560558106/il_340x270.2560558106_fjdi.jpg'},
//     {id: 4, title: 'Подарки с персонализацией', image: 'https://i.etsystatic.com/15999839/r/il/4814a0/2508301243/il_340x270.2508301243_7ldf.jpg'},
//     {id: 5, title: 'Подарки на свадьбу', image: 'https://i.etsystatic.com/8484855/r/il/19c553/2912840179/il_340x270.2912840179_swhk.jpg'}
//   ]
//   const products = new Array<PRODUCT>()
//   if (ids) cnt = ids.length
//   if (!cnt) return products
//   let index = 0
//   for (let i = 0; i < cnt; i++) {
//     if (i < templates.length) index = i
//     else index = Math.floor(Math.random() * templates.length)
//     products.push(initProductCard({id: !ids ? i + 1 : ids[i], title: templates[index].title, mainImage: templates[index].image}))
//   }
//   return products
// }
//
// function getProductForProductPage(id?: number): PRODUCT {
//   const templates = [
//     {id: 1, title: 'Подарки на годовщину', image: 'https://i.etsystatic.com/11486790/r/il/09528c/2809353368/il_340x270.2809353368_l0rq.jpg'},
//     {id: 2, title: 'Подарки для него', image: 'https://i.etsystatic.com/19483987/r/il/42cd8d/2512481731/il_340x270.2512481731_8gz7.jpg'},
//     {id: 3, title: 'Подарки для нее', image: 'https://i.etsystatic.com/15999839/r/il/57349a/2560558106/il_340x270.2560558106_fjdi.jpg'},
//     {id: 4, title: 'Подарки с персонализацией', image: 'https://i.etsystatic.com/15999839/r/il/4814a0/2508301243/il_340x270.2508301243_7ldf.jpg'},
//     {id: 5, title: 'Подарки на свадьбу', image: 'https://i.etsystatic.com/8484855/r/il/19c553/2912840179/il_340x270.2912840179_swhk.jpg'}
//   ]
//   const index = Math.floor(Math.random() * templates.length)
//   const p: PRODUCT = initProductCard({id: !id ? templates[index].id : id, title: templates[index].title, mainImage: templates[index].image})
//   p.images.push(templates[index].image)
//   for (let i = 0; i < templates.length; i++) {
//     if (i !== index) p.images.push(templates[i].image)
//   }
//   return p
// }

interface Link {
  development: string,
  production: string
  getLink: () => string
}

export interface State {
  siteName: string,
  currency: string,
  siteLink: Link,
  apiLink: Link,
  user: User | null,
  favorites: Array<number>,
  cart: Array<number>,
  productViewed: Array<number>,
  popularCategories: Array<CATEGORY>,
  notification: {type: string, text: string}
}

export default createStore<State>({
  state: {
    siteName: 'Julia shop',
    siteLink: getNewLink(),
    apiLink: getNewLink(true),
    currency: 'EUR',
    user: null,
    favorites: [],
    cart: [],
    productViewed: [],
    popularCategories: [],
    notification: {type: 's', text: ''}
  },
  getters: {
    siteName: state => { return state.siteName },
    currency: state => { return state.currency },
    siteLink: state => {
      return function (path?: string) {
        return state.siteLink.getLink() + (path != null ? path : '')
      }
    },
    user: state => { return state.user },
    userAuthorized: state => { return state.user != null },
    // getUser: state => {},
    favorites: state => { return state.favorites },
    favoritesCount: state => { return state.favorites.length },
    isInFavorites: state => {
      return function (id: number) {
        // console.log(`In favorites: ${state.favorites.indexOf(id) > -1}`)
        return state.favorites.indexOf(id) > -1
      }
    },
    cart: state => { return state.cart },
    cartCount: state => { return state.cart.length },
    isInCart: state => {
      return function (id: number) { return state.cart.indexOf(id) > -1 }
    },
    recentlyViewed: state => { return state.productViewed },
    // test getter
    // recentlyViewed: () => {
    //   return function (ids: Array<number>) {
    //     return getTestProducts(0, ids)
    //   }
    // },
    // testProduct: () => {
    //   return function (id?: number) { return getProductForProductPage(id) }
    // },
    popularCategories: state => { return state.popularCategories },
    notification: state => { return state.notification }
  },
  mutations: {
    currency(state, payload: string) {
      state.currency = payload
      console.log('currency commited; new val: ', state.currency)
    },
    user(state, payload: User) {
      state.user = payload
      // console.log('user commited; new val: ', state.user)
    },
    addToFavorites(state, payload: number,) {
      if (state.favorites.indexOf(payload) === -1) state.favorites.push(payload)
      console.log('addedToFavorites: ', JSON.stringify(state.favorites), payload)
    },
    removeFromFavorites(state, payload: number) {
      state.favorites.splice(state.favorites.indexOf(payload), 1)
      console.log('removedFromFavorites: ', JSON.stringify(state.favorites), payload)
    },
    addToCart(state, payload: number) {
      if (state.cart.indexOf(payload) === -1) state.cart.push(payload)
      console.log('addToCart: ', JSON.stringify(state.cart), payload)
    },
    removeFromCart(state, payload: number) {
      state.cart.splice(state.cart.indexOf(payload), 1)
      console.log('removeFromCart: ', JSON.stringify(state.cart), payload)
    },
    notify(state, payload: {type: string, text: string}) {
      // console.log('Store notify!!!')
      state.notification = payload
    }
  },
  actions: {
    init(context) {
      return new Promise((resolve, reject) => {
        try {
          // console.log('init ', process.env.VUE_APP_API_URL)
          context.state.favorites = JSON.parse(localStorage.getItem('favorites') || '[]')
          context.state.cart = JSON.parse(localStorage.getItem('cart') || '[]')
          context.state.productViewed = JSON.parse(localStorage.getItem('activity_watcher') || '[]')
          apiService.get("c").then((r: any): any => {
            // console.log('api resp: ', r)
            if (r && r.data && !r.data.error) {
              const c = r.data.categories
              if (c && c.length) {
                for (let i = 0; i < c.length; i++) {
                  console.log(JSON.stringify(c[i]))
                  context.state.popularCategories.push(newCategorie(c[i]))
                }
              }
            }
            resolve(null)
          })
        } catch(e) {
          reject(e)
        }
      })
    },
    setUser(context, payload: User) {
      context.commit('user', payload)
    },
    setCurrency(context, payload: string) {
      context.commit('currency', payload)
    },
    notify(context, payload: {type: string, text: string}) {
      context.commit('notify', payload)
    },
    async addToFavorites(context, payload: number) {
      await context.commit('addToFavorites', payload)
      localStorage.setItem('favorites', JSON.stringify(context.state.favorites))
    },
    async removeFromFavorites(context, payload: number) {
      await context.commit('removeFromFavorites', payload)
      localStorage.setItem('favorites', JSON.stringify(context.state.favorites))
    }
  },
  modules: {
  }
})
